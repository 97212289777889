define("discourse/plugins/discourse-staff-alias/discourse/initializers/discourse-staff-alias", ["exports", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-common/utils/decorators", "I18n"], function (_exports, _pluginApi, _composer, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const PLUGIN_ID = "discourse-staff-alias";
  function initialize(api) {
    const currentUser = api.getCurrentUser();
    if (currentUser?.can_act_as_staff_alias) {
      var _dec, _obj, _dec2, _dec3, _obj2;
      api.modifySelectKit("composer-actions").prependContent(component => {
        if (component.action === _composer.CREATE_TOPIC) {
          return [{
            name: _I18n.default.t("composer.composer_actions.as_staff_alias.create_topic.label"),
            description: _I18n.default.t("composer.composer_actions.as_staff_alias.create_topic.desc"),
            icon: "user-secret",
            id: "toggle_reply_as_staff_alias"
          }];
        }
      });
      api.modifySelectKit("composer-actions").appendContent(component => {
        if (component.topic?.details?.staff_alias_can_create_post && (component.action === _composer.REPLY || component.action === _composer.EDIT && component.get("post.post_type") !== component.get("site.post_types.whisper") && !component.get("post.is_staff_aliased"))) {
          return [{
            name: _I18n.default.t(`composer.composer_actions.as_staff_alias.${component.action}.label`),
            description: _I18n.default.t(`composer.composer_actions.as_staff_alias.${component.action}.desc`),
            icon: "user-secret",
            id: "toggle_reply_as_staff_alias"
          }];
        }
      });
      api.modifyClass("component:composer-presence-display", (_dec = (0, _decorators.default)("model.replyingToTopic", "model.editingPost", "model.whisper", "model.composerOpened", "isDestroying", "model.isReplyAsStaffAlias"), (_obj = {
        pluginId: PLUGIN_ID,
        state(replyingToTopic, editingPost, whisper, composerOpen, isDestroying, isReplyAsStaffAlias) {
          if (isReplyAsStaffAlias) {
            return "whisper";
          }
          return this._super(...arguments);
        }
      }, (_applyDecoratedDescriptor(_obj, "state", [_dec], Object.getOwnPropertyDescriptor(_obj, "state"), _obj)), _obj)));
      api.modifyClass("component:composer-actions", {
        pluginId: PLUGIN_ID,
        toggleReplyAsStaffAliasSelected(options, model) {
          model.toggleProperty("replyAsStaffAlias");
          if (model.whisper) {
            model.set("whisper", false);
          }
        },
        toggleWhisperSelected(options, model) {
          this._super(...arguments);
          if (model.replyAsStaffAlias) {
            model.set("replyAsStaffAlias", false);
          }
        }
      });
      api.modifyClass("model:composer", (_dec2 = (0, _decorators.observes)("isReplyAsStaffAlias"), _dec3 = (0, _decorators.default)("replyAsStaffAlias", "whisper", "editingPost", "post.is_staff_aliased"), (_obj2 = {
        pluginId: PLUGIN_ID,
        replyAsStaffAlias: false,
        _updateUser() {
          if (this.isReplyAsStaffAlias) {
            const props = {};
            if (this.topic) {
              props._originalUser = this.user;
              props.user = this.get("topic.staff_alias_user");
            }
            this.setProperties(props);
          } else {
            const props = {};
            if (this._originalUser) {
              props.user = this.get("_originalUser");
            }
            this.setProperties(props);
          }
        },
        isReplyAsStaffAlias(replyAsStaffAlias, whisper, editingPost, isStaffAliased) {
          if (editingPost && isStaffAliased) {
            return true;
          } else {
            return !whisper && replyAsStaffAlias;
          }
        }
      }, (_applyDecoratedDescriptor(_obj2, "_updateUser", [_dec2], Object.getOwnPropertyDescriptor(_obj2, "_updateUser"), _obj2), _applyDecoratedDescriptor(_obj2, "isReplyAsStaffAlias", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "isReplyAsStaffAlias"), _obj2)), _obj2)));
      api.serializeOnCreate("as_staff_alias", "isReplyAsStaffAlias");
      api.serializeOnUpdate("as_staff_alias", "isReplyAsStaffAlias");
      api.serializeToTopic("as_staff_alias", "isReplyAsStaffAlias");
      api.includePostAttributes("aliased_username");
      api.includePostAttributes("is_staff_aliased");
      api.addPosterIcon((cfs, attrs) => {
        if (attrs.is_staff_aliased) {
          const props = {
            icon: "user-secret",
            className: "user-title"
          };
          if (attrs.aliased_username) {
            props.text = attrs.aliased_username;
            props.title = _I18n.default.t("discourse_staff_alias.poster_icon_title", {
              username: attrs.aliased_username
            });
            props.url = `/u/${attrs.aliased_username}`;
          } else {
            props.text = _I18n.default.t("discourse_staff_alias.aliased_user_deleted");
          }
          return props;
        }
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-staff-alias",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.staff_alias_enabled) {
        (0, _pluginApi.withPluginApi)("0.10.0", initialize);
      }
    }
  };
});